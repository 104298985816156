import React, {useState, useEffect, createContext} from "react";

export const Context = createContext()

// Provides user state to all of the components that are wrapped with this one
const UserProvider = ({children}) => {
    //console.log(sessionStorage.getItem("user"))
    //JSON.parse(sessionStorage.getItem("user") || false)
    
    const [state, setState] = useState(() => {
        const savedUser = localStorage.getItem("user")
        return (savedUser !== undefined && savedUser !== "undefined" )? JSON.parse(savedUser) : undefined
    })

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(state));        
    }, [state])

    return (
        <Context.Provider value={[state, setState]}>{children}</Context.Provider>
    )
}

export default UserProvider;