import styled from "styled-components";

export const Wrapper = styled.div`
     display: flex;
     height: 100vh;
     justify-content: center;
     align-items: center;
     flex-direction: column;
`

export const Button = styled.button`
     margin-top: 1.5rem;
     background-color: white;
     border-radius: 20px;
     padding-left: 2rem;
     padding-right: 2rem;
     padding-top: 8px;
     padding-bottom: 8px;
     color: #151514;
     font-size: 1rem;
     border: 1px solid rgb(113, 113, 113);
     :hover{
         cursor: pointer;
     }

     :active{
        box-shadow: 0 0 5px 5px rgb(225, 201, 183);
     }
     
`

export const AuthStateDiv = styled.div`
     font-size: 3rem;
     border: 1px solid #00000094;
     border-radius: 20px;
     padding: 40px;
     margin-bottom: 10px;
     color: black;
     padding-bottom: 20px;
     padding-top: 20px;
`