import { useState, useContext } from "react";
import { Context } from "../../context";
import axios from 'axios';

import {Wrapper, FormContainter, Label, Button, Spinner, BtnWrapper, LogoImg, Disclaimer } from './Login.styles'
import logo from '../../images/logo.png'



const Login = () => {

    const [user, setUser] = useContext(Context);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handleLogin = async () => {
          setIsLoading(true)
          try{
             const res = await axios.post('/login', {name: username, password })
             setIsLoading(false)
             setUser(res.data)
          }catch(err){
              console.log(err.response.status)
              setIsLoading(false)
          }
    }

    return(
        <Wrapper>
            
                 <FormContainter>
                 <LogoImg src={logo}/>
                 <form onSubmit={handleLogin}>
                 <Label>Uživatelské jméno</Label>
                 <input 
                   type="text"
                   value={username}
                   onChange={(e) => setUsername(e.target.value)}
                 />

                 <Label>Heslo</Label>
                 <input 
                   type="password"
                   value={password}
                   onChange={(e) => setPassword(e.target.value)}
                 />

                 {isLoading 
                  ? <Spinner><div className="loader">Loading...</div></Spinner>
                  : <BtnWrapper>
                         <Button onClick={handleLogin}>Přihlásit se</Button>
                    </BtnWrapper>
                 }
                 </form>
                 <Disclaimer href="https://www.klenottv.cz/policies/terms-of-service">Podmínky použití</Disclaimer>
            </FormContainter>


        </Wrapper>
    )
}

export default Login;