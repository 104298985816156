import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: flex-start;
    background-color: #f5f5f5;
`

export const FormContainter = styled.div`
    height: 270px;
    position: relative;
    margin-top: 15rem;
    border: 1px solid rgba(0,0,0,0.5);
    border-radius: 15px;
    width: 22%;
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: white;
    //box-shadow:  0 0 10px 5px rgb(225, 201, 183);
    box-shadow: 0 0 10px 5px rgba(225, 201, 183, 0.51);

    padding-top: 4rem;
    padding-bottom: 4rem;

    input{
        display: block;
        margin-top: 4px;
        margin-bottom: 0.5rem;
        border-radius: 15px;
        border: 1px solid rgba(0,0,0,0.5);
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 10px;
        padding-right: 10px;


    }

    @media only screen and (max-width: 1500px) {
      width: 50%;
    }

`

export const Button = styled.button`
     margin-top: 1.5rem;
     background-color: white;
     border-radius: 20px;
     padding-left: 2rem;
     padding-right: 2rem;
     padding-top: 8px;
     padding-bottom: 8px;
     color: #151514;
     font-size: 1rem;
     border: 1px solid rgb(113, 113, 113);
     :hover{
         cursor: pointer;
     }

     :active{
        box-shadow: 0 0 5px 5px rgb(225, 201, 183);
     }
     
`

export const Label = styled.div`
   margin-top: 3px;
      
`

export const BtnWrapper = styled.div`
   position: relative;
   display: flex;
   justify-content: center;
   flex-direction: row;
`


export const Spinner = styled.div`
  /*  position: absolute;
   top: -4em;
   left: 4em; */

   position: absolute;
left: 50%;
top: 110px;


   .loader,
   .loader:before,
   .loader:after {
     background: #dfc19f;
     -webkit-animation: load1 1s infinite ease-in-out;
     animation: load1 1s infinite ease-in-out;
     width: 1em;
     height: 4em;
   }
   .loader {
     color: #dfc19f;
     text-indent: -9999em;
     margin: 88px auto;
     position: relative;
     font-size: 11px;
     -webkit-transform: translateZ(0);
     -ms-transform: translateZ(0);
     transform: translateZ(0);
     -webkit-animation-delay: -0.16s;
     animation-delay: -0.16s;
   }
   .loader:before,
   .loader:after {
     position: absolute;
     top: 0;
     content: '';
   }
   .loader:before {
     left: -1.5em;
     -webkit-animation-delay: -0.32s;
     animation-delay: -0.32s;
   }
   .loader:after {
     left: 1.5em;
   }
   @-webkit-keyframes load1 {
     0%,
     80%,
     100% {
       box-shadow: 0 0;
       height: 4em;
     }
     40% {
       box-shadow: 0 -2em;
       height: 5em;
     }
   }
   @keyframes load1 {
     0%,
     80%,
     100% {
       box-shadow: 0 0;
       height: 4em;
     }
     40% {
       box-shadow: 0 -2em;
       height: 5em;
     }
   }


`

export const LogoImg = styled.img`
      width: 30%;
      margin-bottom: 2rem;

      @media only screen and (max-width: 800px) {
        width: 50%;
      }

`

export const Disclaimer = styled.a`
      position: absolute;
      bottom: 10px;
      font-size: 0.8rem;
      opacity: 0.5;
      color: black;
`