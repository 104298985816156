import { createGlobalStyle} from "styled-components";

// Implements the global css styling
export const GlobalStyle = createGlobalStyle`

    html, body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }


`