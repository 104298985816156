import styled from 'styled-components'
import AppContainer from "./components/AppContainer/AppContainer";

import UserProvider from "./context";
import { GlobalStyle } from "./GlobalStyle";


const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

`

const App = () => {

  return (
    <>
       <UserProvider>
          <Wrapper>
             <AppContainer/>
             <GlobalStyle/>
          </Wrapper>
       </UserProvider> 
    </>
  );
}

export default App;
