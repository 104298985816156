import { useState, useEffect, useContext } from "react";
import { Context } from "../../context";
import Login from '../Login/Login'
import { Wrapper, Button, AuthStateDiv } from "../AppContainer/AppContainer.styles";

import axios from "axios";


const AppContainer = () => {

    const [user, setUser] = useContext(Context)
    const [ipAuthorized, setIpAuthorized] = useState(false)

    // Send logout request and delete local user object
    const logoutHandler = async () => {
          try{
             const res = await axios.delete('/logout')
             setUser(undefined)
          }catch(err){
              console.log(err)
          }
    }

    // Upon successful login fire up an authorizeIP request plus set up authorization loop
    useEffect(() => {
        if(!user) return

        let source = axios.CancelToken.source();
        // An async function to send the authorization request
        const authorizeIP = async () => {
            try{
                const authRes = await axios.post('/users/authorizeIP', {cancelToken: source.token})
                console.log(authRes.data.status)
                setIpAuthorized(true)
               
             }catch(error){
                if(error.response.status === 403) setUser(undefined) // If unauthorized return user to the login page
                setIpAuthorized(false)
             }
        }

        authorizeIP()
        const authInterval = setInterval(authorizeIP, 60000);

        return () => {     // Cancel ongoing axios requests on cleanup
            source.cancel()
            clearInterval(authInterval)
         }
    }, [setUser, user])

    // If no user object return login page
    if(!user) return (<Login/>)

    return (
           <Wrapper> 
               {ipAuthorized && <AuthStateDiv>IP adresa autorizována</AuthStateDiv>}
              <Button onClick={logoutHandler}>Odhlásit se</Button>
          </Wrapper>
    )
}

export default AppContainer;